<template>
  <div class="box">
    <div class="box1">
      <img :src="proxySidebarPoster1" class="img-top" />
    </div>
    <div class="box2">
      <div class="box2_1">
        <img src="@/assets/images/pc/recommend.png" class="img-hot" />
        <span class="hot-title">热门院校推荐</span>
      </div>
      <div class="dark-border">
        <span v-for="school in schools" class="hot-item" @click="onClick(school)">
          <img :src="school.num" class="img-num" />
          <img :src="school.src" class="img-school" />
          <div class="hot-school">{{ school.name }}</div>
        </span>
      </div>
    </div>
    <div class="box3">
      <img :src="proxySidebarPoster2" class="img-bottom" />
    </div>
  </div>
</template>

<script>
import Config from "@/api/service";

export default {
  name: "SchoolHot",
  components: {
  },
  props: {

  },
  computed: {
    proxySidebarPoster1() {
      return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=7`;
    },
    proxySidebarPoster2() {
      return Config.proxy.image + `?ProxyCode=${this.$store.state.proxyCode}&ImageIdentity=8`;
    },
  },
  data() {
    return {
      schools: [{
        num: require("@/assets/images/pc/01.png"),
        src: require("@/assets/images/pc/178.png"),
        name: "中国人民大学",
        code: "10002",
      }, {
        num: require("@/assets/images/pc/02.png"),
        src: require("@/assets/images/pc/149.png"),
        name: "西北工业大学",
        code: "10699",
      }, {
        num: require("@/assets/images/pc/03.png"),
        src: require("@/assets/images/pc/194.png"),
        name: "中国农业大学",
        code: "10019",
      }, {
        num: require("@/assets/images/pc/04.png"),
        src: require("@/assets/images/pc/252.png"),
        name: "重庆大学",
        code: "10611",
      }, {
        num: require("@/assets/images/pc/05.png"),
        src: require("@/assets/images/pc/229.png"),
        name: "西安电子科技大学",
        code: "10701",
      }, {
        num: require("@/assets/images/pc/06.png"),
        src: require("@/assets/images/pc/33.png"),
        name: "南京农业大学",
        code: "10307",
      }, {
        num: require("@/assets/images/pc/07.png"),
        src: require("@/assets/images/pc/220.png"),
        name: "西南交通大学",
        code: "10613",
      }, {
        num: require("@/assets/images/pc/08.png"),
        src: require("@/assets/images/pc/393.png"),
        name: "中国地质大学（北京）",
        code: "11415",
      }, {
        num: require("@/assets/images/pc/09.png"),
        src: require("@/assets/images/pc/186.png"),
        name: "福州大学",
        code: "10386",
      }, {
        num: require("@/assets/images/pc/10.png"),
        src: require("@/assets/images/pc/226.png"),
        name: "长安大学",
        code: "10710",
      }]
    };
  },
  methods: {
    onClick(school) {
      window.open(this.$router.resolve({ name: 'School1', params: { universityCode: school.code } }).href, '_blank');
    }
  },
};
</script>

<style lang="less" scoped>
.box {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.box1 {
  width: 100%;
  height: 170px;
}

.box2 {
  width: 100%;
}

.box2_1 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 0;
}

.box3 {
  width: 100%;
  height: 170px;
}

.img-top {
  width: 100%;
  height: 100%;
}

.img-bottom {
  width: 100%;
  height: 100%;
}

.img-hot {
  width: 20px;
  height: 20px;
}

.img-num {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.img-school {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.hot-title {
  font-size: 20px;
  font-weight: bold;
  margin-left: 10px;
}

.hot-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 20px 10px;
  cursor: pointer;
}

.hot-item:hover {
  .hot-school {
    color: #FE5E03;
  }
}

.hot-school {
  font-size: 15px;
  cursor: pointer;
}

.dark-border {
  border: 1px solid #e6e6e6;
  margin-bottom: 20px;
}
</style>
