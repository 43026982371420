<template>
    <div>
        <van-sticky>
            <title-bar2 title="我的资料" />
        </van-sticky>
        <van-tabs v-model="active" :offset-top="45" sticky :animated="false" @change="onReload">
            <van-tab title="激活资料">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-swipe-cell v-for="(item, index) in list" :key="index">
                        <van-card :thumb="require('@/assets/images/mt/thumb.png')" :centered="false" @click="onClickDocument(item)">
                            <template #tag>
                                <van-tag mark plain type="danger">激活</van-tag>
                            </template>
                            <template #title>
                                <div class="item-name">{{ item.DocumentName }}</div>
                            </template>
                            <template #price>
                                <div class="item-price">上传时间：{{ convertDateTime(item.DocumentUploadTime) }} </div>
                            </template>
                        </van-card>
                    </van-swipe-cell>
                </van-list>
            </van-tab>
            <van-tab title="调剂报告">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-card v-for="(item, index) in list" :key="index" :thumb="require('@/assets/images/mt/thumb.png')" :centered="false">
                        <template #title>
                            <div class="item-name">{{ item.GenerateName }}</div>
                        </template>
                        <template #desc>
                            <div class="item-desc">报告状态：{{ convertReportState(item.GenerateState) }}</div>
                        </template>
                        <template #price>
                            <div class="item-price">提交时间：{{ convertDateTime(item.GenerateCreateTime) }} </div>
                        </template>
                        <template #footer>
                            <a v-if="item.GenerateState == 1" :href="downSchoolAdjustReportUrl(item)">
                                <van-button round hairline size="small" type="primary">下载报告</van-button>
                            </a>
                        </template>
                    </van-card>
                </van-list>
            </van-tab>
            <van-tab title="报录报告">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <van-card v-for="(item, index) in list" :key="index" :thumb="require('@/assets/images/mt/thumb.png')" :centered="false">
                        <template #title>
                            <div class="item-name">{{ item.GenerateName }}</div>
                        </template>
                        <template #desc>
                            <div class="item-desc">报告状态：{{ convertReportState(item.GenerateState) }}</div>
                        </template>
                        <template #price>
                            <div class="item-price">提交时间：{{ convertDateTime(item.GenerateCreateTime) }} </div>
                        </template>
                        <template #footer>
                            <a v-if="item.GenerateState == 1" :href="downSchoolSelectionSingleReportUrl(item)">
                                <van-button round hairline size="small" type="primary">下载报告</van-button>
                            </a>
                        </template>
                    </van-card>
                </van-list>
            </van-tab>
            <van-tab title="择校报告">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了">
                    <van-card v-for="(item, index) in list" :key="index" :thumb="require('@/assets/images/mt/thumb.png')" :centered="false">
                        <template #title>
                            <div class="item-name">{{ item.GenerateName }}</div>
                        </template>
                        <template #desc>
                            <div class="item-desc">报告状态：{{ convertReportState(item.GenerateState) }}</div>
                        </template>
                        <template #price>
                            <div class="item-price">提交时间：{{ convertDateTime(item.GenerateCreateTime) }} </div>
                        </template>
                        <template #footer>
                            <a v-if="item.GenerateState == 1" :href="downSchoolSelectionMultipleReportUrl(item)">
                                <van-button round hairline size="small" type="primary">下载报告</van-button>
                            </a>
                        </template>
                    </van-card>
                </van-list>
            </van-tab>
        </van-tabs>
    </div>
</template>

<script>
import TitleBar2 from "@/components/MT/TitleBar2";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "MTModule",
    components: {
        TitleBar2,
    },
    data() {
        return {
            active: 0,
            index: 0,
            total: 0,
            loading: false,
            finished: false,
            list: [],
        };
    },
    computed: {
        downSchoolSelectionSingleReportUrl() {
            return function (row) {
                return `${Config.account.admitReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
        downSchoolSelectionMultipleReportUrl() {
            return function (row) {
                return `${Config.account.aiSelectionReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
        downSchoolAdjustReportUrl() {
            return function (row) {
                return `${Config.account.smartAdjustReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {

        },
        //转换报告状态
        convertReportState(value) {
            if (value == 0) {
                return "生成中...";
            } else if (value == 1) {
                return "已生成";
            } else if (value == -1) {
                return "异常";
            } else {
                return "未知状态";
            }
        },
        //转换日期
        convertDateTime(value) {
            return Tools.formatDate(new Date(value), 'yyyy-MM-dd HH:mm:ss')
        },
        //重新加载列表
        onReload() {
            this.finished = false;
            this.loading = true;
            this.index = 0;
            this.list = [];
            this.onLoad();
        },
        //加载当前列表
        onLoad() {
            if (!this.$store.getters.isLogin) {
                return
            }
            if (this.active == 0) {
                var model = {
                    ProxyCode: this.$store.state.proxyCode,
                    PageIndex: ++this.index,
                    PageSize: 10,
                };
                this.$axios
                    .post(Config.account.documentList, model)
                    .then((res) => {
                        let data = res.data.Data;
                        this.list.push(...data.DocumentInfos);
                        this.loading = false;
                        this.total = data.TotalCount;
                        if (this.index >= data.PageCount) {
                            this.finished = true;
                        }
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            } else if (this.active == 1) {
                var model = {
                    PageIndex: ++this.index,
                    PageSize: 10,
                };
                this.$axios
                    .post(Config.account.smartAdjustReportList, model)
                    .then((res) => {
                        let data = res.data.Data;
                        this.list.push(...data.SchoolAdjustReportGenerates);
                        this.loading = false;
                        this.total = data.TotalCount;
                        if (this.index >= data.PageCount) {
                            this.finished = true;
                        }
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            } else if (this.active == 2) {
                var model = {
                    PageIndex: ++this.index,
                    PageSize: 10,
                };
                this.$axios
                    .post(Config.account.admitReportList, model)
                    .then((res) => {
                        let data = res.data.Data;
                        this.list.push(...data.SchoolSelectionReportGenerates);
                        this.loading = false;
                        this.total = data.TotalCount;
                        if (this.index >= data.PageCount) {
                            this.finished = true;
                        }
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            } else if (this.active == 3) {
                var model = {
                    PageIndex: ++this.index,
                    PageSize: 10,
                };
                this.$axios
                    .post(Config.account.aiSelectionReportList, model)
                    .then((res) => {
                        let data = res.data.Data;
                        this.list.push(...data.SchoolSelectionReportGenerates);
                        this.loading = false;
                        this.total = data.TotalCount;
                        if (this.index >= data.PageCount) {
                            this.finished = true;
                        }
                    })
                    .catch((error) => {
                        this.$dialog({ title: "错误", message: error.message });
                    });
            }
        },
        //点击资料
        onClickDocument(documentInfo) {
            this.$router.push({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            });
        },
    },
    mounted() {
        this.getRoutParams();
    }
};
</script>

<style scoped>
.delete-button {
    height: 100%;
}

.item-name {
    font-size: 12px;
    margin-top: 5px;
}

.item-desc {
    font-size: 12px;
    color: lightseagreen;
    margin-top: 5px;
}

.item-price {
    font-size: 12px;
    color: gray;
    margin-bottom: 5px;
}

.van-card {
    background-color: white;
}
</style>