<template>
    <div class="content-box">
        <div class="content-left">
            <el-tabs type="card" v-model="activeName">
                <el-tab-pane class="pane-border" name="1" label="激活文档资料">
                    <el-table v-if="activeName == '1'" border size="small" :data="document.list">
                        <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
                        <el-table-column prop="DocumentName" label="资料名称" align="center"></el-table-column>
                        <el-table-column prop="DocumentUploadTime" label="发布时间" align="center" :formatter="formatDateTime" width="130"></el-table-column>
                        <el-table-column label="查看资料" align="center" width="80">
                            <template slot-scope="scope">
                                <el-button type="text" size="small" @click="onDocumentClick(scope.row)">查看</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pagination-box" background hide-on-single-page :current-page.sync="document.index" :page-size.sync="document.size" :total.sync="document.total" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
                </el-tab-pane>
                <el-tab-pane class="pane-border" name="2" label="报录数据报告下载">
                    <el-table v-if="activeName == '2'" border size="small" :data="admit.list">
                        <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
                        <el-table-column prop="GenerateName" label="报告名称" align="center"></el-table-column>
                        <el-table-column prop="UserPhone" label="申请人" align="center" width="100"></el-table-column>
                        <el-table-column prop="GenerateCreateTime" label="提交时间" align="center" :formatter="formatDateTime" width="130"></el-table-column>
                        <el-table-column label="报告状态" align="center" :formatter="formatReportState" width="100"></el-table-column>
                        <el-table-column label="下载文件" align="center" width="80">
                            <template slot-scope="scope">
                                <a v-if="scope.row.GenerateState == 1" :href="downAdmitReportUrl(scope.row)">下载</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pagination-box" background hide-on-single-page :current-page.sync="admit.index" :page-size.sync="admit.size" :total.sync="admit.total" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
                </el-tab-pane>
                <el-tab-pane class="pane-border" name="3" label="智慧调剂报告下载">
                    <el-table v-if="activeName == '3'" border size="small" :data="smartAdjust.list">
                        <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
                        <el-table-column prop="StudentName" label="学员名字" align="center"></el-table-column>
                        <el-table-column prop="UserPhone" label="申请人" align="center" min-width="100"></el-table-column>
                        <el-table-column prop="UniversityName" label="报考学校" align="center"></el-table-column>
                        <el-table-column prop="MajorCode" label="专业代码" align="center"></el-table-column>
                        <el-table-column prop="MajorName" label="专业名称" align="center"></el-table-column>
                        <el-table-column label="初试总分" align="center" :formatter="formatTotalScore"></el-table-column>
                        <el-table-column prop="GenerateCreateTime" label="提交时间" align="center" :formatter="formatDateTime" width="130"></el-table-column>
                        <el-table-column label="报告状态" align="center" :formatter="formatReportState"></el-table-column>
                        <el-table-column label="下载文件" align="center">
                            <template slot-scope="scope">
                                <a v-if="scope.row.GenerateState == 1" :href="downSmartAdjustReportUrl(scope.row)">下载</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pagination-box" background hide-on-single-page :current-page.sync="smartAdjust.index" :page-size.sync="smartAdjust.size" :total.sync="smartAdjust.total" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
                </el-tab-pane>
                <el-tab-pane class="pane-border" name="4" label="AI择校报告下载">
                    <el-table v-if="activeName == '4'" border size="small" :data="aiSelection.list">
                        <el-table-column type="index" width="50" label="序号" align="center"> </el-table-column>
                        <el-table-column prop="GenerateName" label="报告名称" align="center"></el-table-column>
                        <el-table-column prop="UserPhone" label="申请人" align="center" width="100"></el-table-column>
                        <el-table-column prop="GenerateCreateTime" label="提交时间" align="center" :formatter="formatDateTime" width="130"></el-table-column>
                        <el-table-column label="报告状态" align="center" :formatter="formatReportState" width="100"></el-table-column>
                        <el-table-column label="下载文件" align="center" width="80">
                            <template slot-scope="scope">
                                <a v-if="scope.row.GenerateState == 1" :href="downAiSelectionReportUrl(scope.row)">下载</a>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination class="pagination-box" background hide-on-single-page :current-page.sync="aiSelection.index" :page-size.sync="aiSelection.size" :total.sync="aiSelection.total" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
                </el-tab-pane>
            </el-tabs>
        </div>
        <div class="content-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import HeaderEntry from "@/components/PC/HeaderEntry";
import SchoolHot from "@/components/PC/SchoolHot";
import Tools from "@/tool";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolHot,
        HeaderEntry,
    },
    data() {
        return {
            activeName: '1',
            document: {
                list: [],
                index: 1,
                size: 10,
                total: 0,
            },
            admit: {
                list: [],
                index: 1,
                size: 10,
                total: 0,
            },
            smartAdjust: {
                list: [],
                index: 1,
                size: 10,
                total: 0,
            },
            aiSelection: {
                list: [],
                index: 1,
                size: 10,
                total: 0,
            },
        };
    },
    computed: {
        downAdmitReportUrl() {
            return function (row) {
                return `${Config.account.admitReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
        downSmartAdjustReportUrl() {
            return function (row) {
                return `${Config.account.smartAdjustReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
        downAiSelectionReportUrl() {
            return function (row) {
                return `${Config.account.aiSelectionReportDownload}?GenerateGuid=${row.GenerateGuid}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {

        },
        //格式化时间
        formatDateTime(row, column, cellValue, index) {
            return Tools.formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm')
        },
        //格式化考试总分
        formatTotalScore(row, column, cellValue, index) {
            return row.PoliticalScore + row.EnglishScore + row.MajorScore1 + row.MajorScore2;
        },
        //格式化报告状态
        formatReportState(row, column, cellValue, index) {
            if (row.GenerateState == 0) {
                return "生成中...";
            } else if (row.GenerateState == 1) {
                return "已生成";
            } else if (row.GenerateState == -1) {
                return "异常";
            } else {
                return "未知状态";
            }
        },
        //获取激活资料列表
        getDocumentList() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {
                ProxyCode: this.$store.state.proxyCode,
                ProductType: 0,
                PageIndex: this.document.index,
                PageSize: this.document.size,
            };
            this.$axios
                .post(Config.account.documentList, model)
                .then((res) => {
                    this.document.list = res.data.Data.DocumentInfos;
                    this.document.total = res.data.Data.TotalCount;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取报录数据报告生成列表
        getAdmitReportList() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {
                PageIndex: this.admit.index,
                PageSize: this.admit.size,
            };
            this.$axios
                .post(Config.account.admitReportList, model)
                .then((res) => {
                    this.admit.list = res.data.Data.SchoolSelectionReportGenerates;
                    this.admit.total = res.data.Data.TotalCount;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取智慧调剂报告生成列表
        getSmartAdjustReportList() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {
                PageIndex: this.smartAdjust.index,
                PageSize: this.smartAdjust.size,
            };
            this.$axios
                .post(Config.account.smartAdjustReportList, model)
                .then((res) => {
                    this.smartAdjust.list = res.data.Data.SchoolAdjustReportGenerates;
                    this.smartAdjust.total = res.data.Data.TotalCount;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //获取AI择校报告生成列表
        getAiSelectionReportList() {
            if (!this.$store.getters.isLogin) {
                return
            }
            var model = {
                PageIndex: this.aiSelection.index,
                PageSize: this.aiSelection.size,
            };
            this.$axios
                .post(Config.account.aiSelectionReportList, model)
                .then((res) => {
                    this.aiSelection.list = res.data.Data.SchoolSelectionReportGenerates;
                    this.aiSelection.total = res.data.Data.TotalCount;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击分页
        onCurrentChange() {
            if (this.activeName == '1') {
                this.getDocumentList();
            } else if (this.activeName == '2') {
                this.getAdmitReportList();
            } else if (this.activeName == '3') {
                this.getSmartAdjustReportList();
            } else if (this.activeName == '4') {
                this.getAiSelectionReportList();
            }
        },
        //点击查看资料
        onDocumentClick(documentInfo) {
            window.open(this.$router.resolve({
                name: 'Document1',
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    documentGuid: documentInfo.DocumentGuid
                }
            }).href, '_blank')
        },
    },
    mounted() {
        this.getRoutParams();
        this.getDocumentList();
        this.getAdmitReportList();
        this.getAiSelectionReportList();
        this.getSmartAdjustReportList();
    }
};
</script>
<style scoped>
.content-box {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}

.content-left {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.content-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.content-border {
    border: 1px solid #e6e6e6;
    padding: 10px 30px;
}

.content-document {
    margin: 10px 0;
}

.content-document-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #555;
    font-size: 14px;
    margin: 10px 0;
}

.content-document-item-name {
    width: calc(100% - 200px);
    cursor: pointer;
    color: #555;
    font-size: 14px;
}

.content-document-item-name:hover {
    color: #FE5E03;
}

.content-document-item-date {
    color: #555;
    font-size: 14px;
}



.pane-border {
    padding: 20px;
    border-left: 1px solid #e6e6e6;
    border-right: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}

::v-deep .el-tabs__header {
    margin: 0px;
}

::v-deep .el-tabs--card>.el-tabs__header .el-tabs__nav {
    border-radius: 0px;
}
</style>